import styled from 'styled-components'

import Config from './Config'

export const Header = styled.div`
    height: 76px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 25px;
    box-shadow: 0px 4px 4px 0px #0000001A;

    img {
        width: 84px;
        height: 40px;
    }
`

export const ContainerBgDesktop = styled.div`
    // hide bg on mobile
    @media screen and (max-width: 1300px) {
        display: none;
    }

    width: calc(100% - 640px);
    height: 100vh;
    background-image: url('/bg-inicial.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
`

export const AlignBgDesktop = styled.div`
    background-image: ${props => props.enableBg ? "url('/bg-desktop.png')" : ''};
    background-size: cover;

    @media screen and (max-width: 1300px) {
        padding: 30px;
    }

    #ContainerInicial {
        padding-top: 50px;
    }

    > div {
        width: ${props => props.enableBg ? '100vw' : ''};

        > div {
            background-color: #f9f9f9;
            margin: 0 auto;
            margin-top: 27px;
            padding-bottom: 20px;

            @media screen and (min-width: 1300px) {
                width: 640px;
            }

            @media screen and (max-width: 1300px) {
                margin-top: -20px !important;
            }
        }
    }
`

export const ContainerInicial = styled.div`
    margin-bottom: 20px;
    background-color: #F9F9F9;
    height: 100%;

    // max-width para mobile
    @media screen and (max-width: 1300px) {
        max-width: 90vw;
    }
    
    // max-width para desktop
    @media screen and (min-width: 1300px) {
        max-width: 640px;
        margin: 0 auto;
        padding-left: 130px;
        padding-top: 100px;
    }
`

export const ContainerUpload = styled.div`
    // max-width para mobile
    @media screen and (max-width: 1300px) {
        max-width: 90vw;
    }
    
    // max-width para desktop
    @media screen and (min-width: 1300px) {
        max-width: 640px;
        width: 640px;
    }
    
    div#select-photo {
        height: 329px;
        width: 100%;
        /* background-color: ${Config.GREY}; */
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        
        img {
            height: 245px;
            margin-top: 49px;
        }
    }
`

export const ContainerAdjustImg = styled.div`
    height: 600px;
    
    // max-width para mobile
    @media screen and (max-width: 1300px) {
        max-width: 90vw;
    }
    
    // max-width para desktop
    @media screen and (min-width: 1300px) {
        max-width: 640px;
    }

    div#crop-area .reactEasyCrop_Container {
        max-width: 350px;
        top: 190px !important;
        height: 300px !important;
        width: 367px;
        margin: 0 auto;
    }

    // posição da imagem no desktop
    @media screen and (min-width: 1300px) {
        div#crop-area .reactEasyCrop_Container {
        }
    }
    
    #slider-zoom {
        margin-top: 337px;
        margin-left: 20px;
        max-width: 367px;
        display: flex;

        // posição da imagem no desktop
        @media screen and (min-width: 1300px) {
            margin-top: 374px !important;
            margin-left: 127px !important;
        }

        #slider-component {
            margin-right: 40px;
            margin-top: -5px;
        }

        #button-deletar-foto {
            width: 108px;
            height: 30px;
            background: rgba(229, 32, 39, 0.2);
            border-radius: 7px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #FF141E;
            padding: 3px 14px;
            margin-right: 20px;
        }
    }
`

export const ContainerShare = styled.div`
    // max-width para mobile
    @media screen and (max-width: 1300px) {
        max-width: 90vw;
    }
    
    // max-width para desktop
    @media screen and (min-width: 1300px) {
        max-width: 640px;
    }

    .foto-cropped {
        width: 230px;
        margin-top: 73px;
        margin-left: 94px;
        position: absolute;
        overflow: hidden;
        
        div {
            background: url(${props => props.croppedImage}) no-repeat left center;
            background-size: contain;
            background-origin: content-box;
            background-position: 18px;
            width: 230px;
            height: 230px;
            margin-top: -23px;
            margin-left: -44px;
            padding-left: 24px;
            padding-top: 22px;
        }
    }

    #img-to-share {
        width: 280px;
        height: 280px;
        z-index: 2;
        position: absolute;
    }

    #shareble-img-preview {
        width: 280px;
        height: 280px;

        // align on desktop
        @media screen and (min-width: 1300px) {
            margin-left: 55px;
        }
    }

    #mudar-foto:hover { cursor: pointer }

    #mudar-foto {
        display: flex;
        padding-top: 20px;

        // align on desktop
        @media screen and (min-width: 1300px) {
            margin-left: 19px;
        }

        #text-nao-gostou {
            margin-left: 34px;
            margin-top: 7px;
            margin-right: 14px;
        }

        #button-mudar-foto {
            width: 137px;
            height: 30px;
            background: rgba(229, 32, 39, 0.2);
            border-radius: 7px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #FF141E;
            padding: 3px 19px;

            span {
                white-space: nowrap;
            }
        }
    }

    #blank-bar-1 {
        background-color: #f9f9f9;
        width: 36px;
        height: 293px;
        position: absolute;
        top: 325px;
        left: 697px;
    }

    #blank-bar-2 {
        background-color: #f9f9f9;
        width: 293px;
        height: 14px;
        position: absolute;
        top: 605px;
        left: 418px;
    }

    @media screen and (max-width: 1300px) {
            #resultado-final {
            width: 267px;

            #text-nao-gostou {
                margin-left: 0;
                margin-top: -5px;
            }
        }
    }
`

export const ButtonBlue = styled.div`
    background-color: ${Config.PRIMARY_COLOR};
    height: 54px;
    width: 90%;
    border-radius: 4px;
    text-align: center;
    padding-top: 16px;
    max-width: 300px;

    @media screen and (min-width: 1300px) {
        margin-left: 130px;
    }

    span {
        font-weight: 700;
        font-size: 18px;
        margin-right: 20px;
        margin-left: 20px;
    }

    span, svg {
        color: #fff;
        vertical-align: middle
    }
`

export const ButtonGreen = styled.div`
    background-color: ${Config.GREEN};
    height: 54px;
    width: 90%;
    border-radius: 4px;
    text-align: center;
    padding-top: 16px;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 390px;

    span {
        font-weight: 700;
        font-size: 18px;
        margin-right: 20px;
        margin-left: 20px;
    }

    span, svg {
        color: #fff;
        vertical-align: middle
    }
`

export const ButtonRed = styled.div`
    background-color: ${Config.RED};
    height: 54px;
    width: 90%;
    border-radius: 4px;
    text-align: center;
    padding-top: 16px;
    margin: 0 auto;
    margin-top: 20px;

    span {
        font-weight: 700;
        font-size: 18px;
        margin-right: 20px;
        margin-left: 20px;
    }

    span, svg {
        color: #fff;
        vertical-align: middle
    }
`

export const ButtonGray = styled.div`
    background-color: ${Config.GREY};
    height: 54px;
    width: 90%;
    border-radius: 4px;
    text-align: center;
    padding-top: 16px;
    max-width: 300px;

    @media screen and (min-width: 1300px) {
        margin-left: 130px;
    }

    span {
        font-weight: 700;
        font-size: 18px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    span, svg {
        color: ${Config.SECONDATY_COLOR};
        vertical-align: middle
    }
`

export const ButtonLightBlue = styled.div`
    background-color: ${Config.LIGHTBLUE};
    height: 54px;
    width: 90%;
    border-radius: 4px;
    text-align: center;
    padding-top: 16px;
    margin: 0 auto;
    margin-top: 10px;
    max-width: 390px;

    span {
        font-weight: 700;
        font-size: 18px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    span, svg {
        color: #fff;
        vertical-align: middle
    }
`

export const ContainerGenerateImg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgb(0 0 0 / 59%);
    display: none;

    #shareble-img {
        margin: 0 auto;
    }

    .foto-cropped {
        width: 800px;
        margin-top: 40px;
        margin-left: 45px;
        position: absolute;
        overflow: hidden;
        
        div {
            background: url(${props => props.croppedImage}) no-repeat left center;
            background-size: contain;
            background-origin: content-box;
            background-position: 187px;
            width: 750px;
            height: 760px;
            margin-left: 5px;
            padding-left: 24px;
            padding-top: 173px;
        }
    }

    #img-to-share {
        width: 800px;
        height: 800px;
        z-index: 2;
        position: absolute;
    }

    #shareble-img {
        width: 800px;
        height: 800px;
    }
`