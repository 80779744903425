const Config = {
    PRIMARY_COLOR: '#ec1651',
    SECONDATY_COLOR: '#3C4E51',
    BLACK_COLOR: '#75777A',
    GREEN: '#00B845',
    GREY: '#EAEAEA',
    LIGHTBLUE: '#9FB0BF',
    RED: '#FF141E'
}

export default Config