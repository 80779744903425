import React, { useState, useCallback, useEffect } from 'react'
import GlobalStyle from './GlobalStyle'
import {
  Header,
  ContainerBgDesktop,
  ButtonBlue,
  ButtonGray,
  ButtonGreen,
  ButtonRed,
  ButtonLightBlue,
  ContainerInicial,
  ContainerUpload,
  ContainerAdjustImg,
  ContainerShare,
  AlignBgDesktop,
  ContainerGenerateImg
} from './AppStyle'

import * as Icon from 'react-feather'

import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import html2canvas from 'html2canvas'

import Slider from '@mui/material/Slider'

import { toBase64 } from './convertBase64'

const App = () => {

  const [container, setContainer] = useState(0)

  function nextContainer() {
    let next = container + 1
    setContainer(next)
    window.history.pushState("FMX-ORGULHO", "FMX-ORGULHO", `/process=${next}`);
  }

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  })
  const [zoom, setZoom] = useState(0)

  // desativa botão finalizar para que o usuário seja forçado a ajustar o zoom
  const [buttonFinalizarEnabled, setButtonFinalizarEnabled] = useState(false)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params?.process) {
      setContainer(Number(params.process))
    }

    setTimeout(() => {
      setZoom(3)
      setCrop({ x: 30, y: 30 })
    }, 1)

    let cropContainer = document.querySelector('.reactEasyCrop_Container')

    let sliderComponent = document.querySelector('#slider-component')

    cropContainer.addEventListener('click', () => {
      setButtonFinalizarEnabled(true)
    })

    sliderComponent.addEventListener('click', () => {
      setButtonFinalizarEnabled(true)
    })

    cropContainer.addEventListener('touchstart', () => {
      setButtonFinalizarEnabled(true)
    })

    sliderComponent.addEventListener('touchstart', () => {
      setButtonFinalizarEnabled(true)
    })

  }, [])

  const onCropComplete = (croppedArea, croppedAreaPixelsToSet) => {
    setCroppedAreaPixels(croppedAreaPixelsToSet)    
  }

  async function handleFileSelect(evt) {
    var file = evt.target.files[0] // FileList object

    //Converting Binary Data to base 64
    var base64String = await toBase64(file)
        
    // store img in localStorage
    localStorage.setItem('foto-uploaded', base64String)

    window.location.href = '/?process=2'
  }

  const showCroppedImage = useCallback(async () => {

    try {
      const croppedImage = await getCroppedImg(
        localStorage.getItem('foto-uploaded'),
        croppedAreaPixels
      )
      console.log('donee', { croppedImage })
      localStorage.setItem('foto-cropped', croppedImage)
      nextContainer()
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels])

  function handleDisplayGenerateContainer(action) {

    if (action === 'show') {
      document.getElementById('container-generate').style.display = 'unset'
    }
    
    if (action === 'hide') {
      document.getElementById('container-generate').style.display = 'none'
    }

  }

  async function handleShare() {

    handleDisplayGenerateContainer('show')

    const canvas = await html2canvas(document.getElementById('shareble-img'), { scale: 3 })
    const dataUrl = canvas.toDataURL()
    const blob = await (await fetch(dataUrl)).blob()
    const filesArray = [
      new File(
        [blob],
        'animation.png',
        {
          type: blob.type,
          lastModified: new Date().getTime()
        }
      )
    ]
    const shareData = {
      files: filesArray,
    }
    navigator.share(shareData)

    handleDisplayGenerateContainer('hide')
  }

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement('a');
    elem.href = blob
    elem.download = fileName;
    elem.style = 'display: none;';
    elem.style = 'background-color: rgb(229 32 39);';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }));
    }
    URL.revokeObjectURL(elem.href);
    elem.remove()

    handleDisplayGenerateContainer('hide')

  }

  async function handleDownload() {
    handleDisplayGenerateContainer('show')

    var html = document.getElementsByTagName('HTML')[0]
    var body = document.getElementsByTagName('BODY')[0]
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;
    var data = document.getElementById('shareble-img')

    var newWidth = data.scrollWidth - data.clientWidth
    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth
      bodyWidth += newWidth
    }
    html.style.width = htmlWidth + 'px'
    body.style.width = bodyWidth + 'px'

    html2canvas(data, { scale: 3 }).then((canvas) => {
      var image = canvas.toDataURL('image/png', 1.0);
      var fileName = 'umadecampi-eu-fui.png'

      saveAs(image, fileName)
    })

  }

  function returnToUmadecampiSite() {
    window.location.href = 'https://umadecampi.com.br/'
  }

  const [dialogMudar, setDialogMudar] = useState(false)
  const [dialogDeletar, setDialogDeletar] = useState(false)

  return (

    <div className="App">

      <GlobalStyle />

      <div id="bg-confirm-action" className={dialogMudar || dialogDeletar ? '' : 'disabled'}>

        <div id="confirm-action-mudar-foto" className={dialogMudar ? '' : 'disabled'}>
          <div className="default-padding" style={{ padding: '4px 15px' }}>

            <h2>Mudar foto</h2>
            <p>Você deseja realmente mudar sua foto?</p>

            <div className="align-button-actions">
              <ButtonGray style={{ width: 134, marginLeft: 0 }} onClick={() => setDialogMudar(false)} className="cursor-pointer">
                <span>Cancelar</span>
              </ButtonGray>

              <ButtonRed style={{ width: 143, marginLeft: 5 }} onClick={() => { setContainer(1); setDialogMudar(false) }} className="cursor-pointer">
                <span>Sim, mudar</span>
              </ButtonRed>
            </div>

          </div>
        </div>

        <div id="confirm-action-excluir-foto" className={dialogDeletar ? '' : 'disabled'}>
          <div className="default-padding" style={{ padding: '4px 15px' }}>

            <h2>Deletar foto</h2>
            <p>Você deseja realmente deletar essa foto?</p>

            <div className="align-button-actions">
              <ButtonGray style={{ width: 134, marginLeft: 0 }} onClick={() => setDialogDeletar(false)} className="cursor-pointer">
                <span>Cancelar</span>
              </ButtonGray>

              <ButtonRed style={{ width: 143, marginLeft: 3 }} onClick={() => { setContainer(1); setDialogDeletar(false) }} className="cursor-pointer">
                <span>Sim, Deletar</span>
              </ButtonRed>
            </div>

          </div>
        </div>

      </div>

      {/* <Header className={container === 0 ? 'disabled' : ''}>
        <img src="/logo-fmx.png" alt="logo" />
      </Header> */}

      <AlignBgDesktop id="align-bg-desktop" enableBg={container !== 0 && window.innerWidth > 1300}>

        <div>

          <div className={container !== 0 ? 'disabled' : ''} id="ContainerInicial">
            <ContainerInicial>

              <img src="/logo-umadecampi.png" alt="logo" />
              <h1>Nós SOMOS UM! Mostre a nossa força nas redes sociais</h1>
              <p>É bem simples! Basta clicar no botão e seguir as instruções. No final, você poderá fazer download de seu arquivo ou compartilhar nas suas redes sociais.</p>

            </ContainerInicial>

            <ButtonBlue onClick={nextContainer} className="cursor-pointer">
              <span>Clique para começar!</span>
              <Icon.ArrowRight />
            </ButtonBlue>

            <ButtonGray style={{ marginTop: 14 }} onClick={returnToUmadecampiSite} className="cursor-pointer">
              <span>Ir para o site UMADECAMPI</span>
            </ButtonGray>
          </div>

          <div className={container !== 1 ? 'disabled' : ''}>
            <ContainerUpload>
              <div className="default-padding">
                <h2>Insira sua foto</h2>
                <p>Tire uma foto com a sua câmera e inseri da sua galeria. Nós não armazenamos suas fotos em nossos servidores.</p>
              </div>

              <div id="select-photo">
                <img src="/tirar-foto.png" alt="Seleciona uma foto" />
              </div>
            </ContainerUpload>

            <ButtonBlue id="button-upload" className="cursor-pointer">
              <Icon.Camera />
              <span>Inserir foto</span>
              {/* capture="filesystem" => para forçar a puxar somente a câmera */}
              <input type="file" id="photo-upload" onChange={handleFileSelect} accept="image/*" />
            </ButtonBlue>
          </div>

          <div className={container !== 2 ? 'disabled' : ''}>

            <ContainerAdjustImg>
              <div className="default-padding">
                <h2>Posicione</h2>
                <p>Agora, ajuste a foto da maneira que melhor desejar. Quando estiver OK, é só apertar o botão "Finalizar".</p>
              </div>

              <div id="crop-area">
                <Cropper
                  image={localStorage.getItem('foto-uploaded')}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="rect"
                  showGrid={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>

              <div id="slider-zoom">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, zoom) => setZoom(zoom)}
                  id="slider-component"
                />
                <div id="button-deletar-foto" onClick={() => setDialogDeletar(true)}>
                  <span>Deletar</span>
                </div>
              </div>
            </ContainerAdjustImg>

            <ButtonBlue onClick={showCroppedImage} className={!buttonFinalizarEnabled ? 'disabled' : 'cursor-pointer'}>
              <span>Finalizar</span>
            </ButtonBlue>

            <ButtonGray onClick={() => console.log(buttonFinalizarEnabled)} className={buttonFinalizarEnabled ? 'disabled' : ''}>
              <span>Finalizar</span>
            </ButtonGray>

          </div>


          <div className={container !== 3 ? 'disabled' : ''}>

            <ContainerShare croppedImage={localStorage.getItem('foto-cropped')}>

              <div className="default-padding">
                <h2>PRONTINHO!</h2>
                <p><strong>E aí, gostou do resultado?</strong><br />Então, compartilhe em suas redes sociais!</p>
                <p>E não se esqueça de colocar a hashtag <strong style={{ color: '#AB0033' }}>#SomosUm</strong> para mostrar a força da nossa união! UMADECAMPI 2022</p>
              </div>

              <div className='align-flex'>
                <div id="resultado-final">
                  <div className="shareble-img" id="shareble-img-preview">
                    <div className="foto-cropped">
                      <div id="insert-foto-cropped"></div>
                    </div>
                    <img src="/shared-img.png" alt="Imagem para compartilhar" id="img-to-share" />
                  </div>

                  <div id="mudar-foto" onClick={() => setDialogMudar(true)}>
                    <span id="text-nao-gostou">Não gostou do resultado?</span>
                    <div id="button-mudar-foto">
                      <span>Mudar foto</span>
                    </div>
                  </div>
                </div>

                <div id="buttons-final">
                  <ButtonGreen onClick={handleShare} id="button-share" className="cursor-pointer">
                    <span>Compartilhar</span>
                    <Icon.Share2 />
                  </ButtonGreen>

                  <ButtonLightBlue onClick={handleDownload} id="button-download-mobile" className="cursor-pointer">
                    <span>Fazer download</span>
                    <Icon.Download />
                  </ButtonLightBlue>

                  <ButtonGreen onClick={handleDownload} id="button-download-desktop" style={{ marginTop: 40 }} className="cursor-pointer">
                    <span>Fazer download</span>
                    <Icon.Download />
                  </ButtonGreen>

                  <ButtonGray style={{ margin: '14px auto 0 auto', maxWidth: 390 }} onClick={returnToUmadecampiSite} className="cursor-pointer">
                    <span>Ir para o site UMADECAMPI</span>
                  </ButtonGray>
                </div>
              </div>

              {/* <div id="blank-bar-1"></div> */}
              {/* <div id="blank-bar-2"></div> */}

            </ContainerShare>
          </div>
        </div>

        <ContainerBgDesktop className={container !== 0 ? 'disabled' : ''}></ContainerBgDesktop>

      </AlignBgDesktop>

      <ContainerGenerateImg croppedImage={localStorage.getItem('foto-cropped')} id="container-generate">

        <div className="shareble-img" id="shareble-img">
          <div className="foto-cropped">
            <div className="insert-foto-cropped"></div>
          </div>
          <img src="/shared-img.png" alt="Imagem para compartilhar" id="img-to-share" />
        </div>

      </ContainerGenerateImg>

    </div>
  )
}

export default App
