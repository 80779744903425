// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript

/**
 * Resize a base 64 Image
 * @param {String} file - Arquivo que vem do <input> para ser convertido a base64
 */

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})
