import { createGlobalStyle } from 'styled-components'

import Config from './Config'

export default createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

    span, p, h1, h2, h3 { font-family: 'Open Sans', sans-serif }

    * { box-sizing:border-box }
    
    // hide buttons on desktop
    @media screen and (min-width: 430px) {
        #button-share, #button-download-mobile {
            display: none;
        }
    }
    
    // hide button on mobile
    @media screen and (max-width: 430px) {
        #button-download-desktop {
            display: none;
        }
    }

    a, a:hover, a:focus { text-decoration: none }

    html, body {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: #F9F9F9;
    }

    .disabled { display: none !important }

    h1, h2 {
        font-size: 32px;
        font-weight: 700;
        color: ${Config.SECONDATY_COLOR}
    }

    p {
        font-size: 14px;
        line-height: 150%;
        color: ${Config.BLACK_COLOR}
    }

    // padding para mobile
    @media screen and (max-width: 430px) {
        div.default-padding { padding: 10px 15px }
    }
    
    // padding para desktop
    @media screen and (min-width: 430px) {
        div.default-padding { padding: 10px 55px }
    }

    div#align-bg-desktop {
        display: flex;
        /* removemos o header porque ele não cabia no height do mobile, então não precisa mais subtrair o tamanho dele (76px) */
        /* height: calc(100vh - 76px); */
        height: 100vh;
    }

    #photo-upload {
        opacity: 0.0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height:100%;
    }

    #button-upload {
        position: relative;

        @media screen and (min-width: 1300px) {
            margin: 0 auto;
        }
    }
    
    #button-upload:hover, #photo-upload:hover{
        cursor: pointer;
    }

    #bg-confirm-action {
        background: rgba(92, 122, 150, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;

        .align-button-actions {
            display: flex;
            align-items: end;
            margin-top: -26px;
        }

        #confirm-action-mudar-foto, #confirm-action-excluir-foto {
            width: 310px;
            height: 246px;
            background: #FFFFFF;
            border-radius: 5px;
            z-index: 9999;
            position: fixed;
            top: 150px;
            right: 40px;

            span {
                white-space: nowrap;
            }

            @media screen and (min-width: 430px) {
                right: 35% !important;
            }
         }
    }

    .cursor-pointer:hover, .cursor-pointer:hover div, .cursor-pointer:hover span {
        cursor: pointer !important;
        opacity: .9 !important;
    }

    .align-flex {
        display: flex;

        @media screen and (max-width: 1300px) {
            display: unset !important;
        }
     
        #buttons-final {
            min-width: 252px;
            margin-left: -30px;
        }
    }
`